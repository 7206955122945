import React from 'react'

import SocketConnector from './components'

const WebSocket = (props) => {
  return <SocketConnector {...props} />
}

export {
  WebSocket
}